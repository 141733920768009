import { PostRequest, GetRequest, successParam } from '@/api/base';

export function getDeviceList(params: object, callback: Function) {
    GetRequest('v3/web/single/device/getList', params, callback);
}

export function deleteDevice(params: object, callback: successParam) {
    PostRequest('v3/web/single/device/delete', params, callback);
}
export function getListMacLibrary(params: object, callback: Function) {
    GetRequest('v3/web/single/macLibrary/getList', params, callback);
}
export function addMacLibrary(params: object, callback: successParam) {
    PostRequest('v3/web/single/macLibrary/add', params, callback);
}
export function deleteMacLibrary(params: object, callback: successParam) {
    PostRequest('v3/web/single/macLibrary/delete', params, callback);
}
