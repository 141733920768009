
import {
    defineComponent, ref, PropType, reactive
} from 'vue';
import { device } from '@/methods/rule';
import { addMacLibrary } from '@/api/single/device';

export default defineComponent({
    props: {
        id: {
            type: [String, null] as PropType<string | null>,
            default: null
        }
    },
    setup(props, { emit }) {
        const visibleDialog = ref(true);
        const formRef = ref();
        const formData = reactive({
            MAC: ''
        });
        const formRules = {
            MAC: [
                { required: true, message: WordList.RuleMacEmpty, trigger: 'blur' },
                { validator: device.checkMAC, trigger: 'blur' }
            ]
        };
        // 提交添加的mac
        function submitAddMacLibrary() {
            formRef.value.form.validate((valid: boolean) => {
                if (valid) {
                    addMacLibrary({ ...formData, InsId: props.id }, () => {
                        emit('addMacLibrarySuccess');
                        visibleDialog.value = false;
                    });
                }
            });
        }

        return {
            visibleDialog,
            formData,
            formRules,
            submitAddMacLibrary,
            formRef
        };
    }
});

